var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-2"},[_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.tasksDialog),callback:function ($$v) {_vm.tasksDialog=$$v},expression:"tasksDialog"}},[(_vm.selectedStudent)?_c('v-card',[_c('v-card-title',{class:("main-" + (_vm.subject.color) + "-bg white--text")},[_vm._v(" "+_vm._s(_vm.selectedStudent.firstName)+" "+_vm._s(_vm.selectedStudent.lastName)+" ")]),_c('v-card-text',{staticClass:"pa-3"},[_c('h4',[_vm._v("Tasks :")]),(_vm.selectedStudent.assessments.length > 0)?_c('div',_vm._l((_vm.selectedStudent.assessments),function(assessment,index){return _c('v-card',{key:index,staticClass:"mt-3",attrs:{"elevation":"4","outlined":""}},[_c('v-card-title',[_c('h5',[_vm._v(_vm._s(assessment.title))])]),_c('v-card-subtitle',[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(assessment.performanceTask),expression:"assessment.performanceTask"}],staticClass:"mr-2",attrs:{"outlined":"","label":"","small":""}},[_vm._v(" Performance Tasks ")]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(assessment.writtenWork),expression:"assessment.writtenWork"}],attrs:{"outlined":"","label":"","small":""}},[_vm._v(" Written Works ")])],1),_c('v-card-text',[_c('p',[_vm._v(" Attempts : "+_vm._s(assessment.studentAssessments.length)+" out of "+_vm._s(assessment.attempts)+" ")]),_c('p',[_vm._v(" Score : "+_vm._s(_vm.getScore( assessment.studentAssessments, assessment.recordHighest ))+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":assessment.studentAssessments.length === 0,"color":"blue accent-4","dark":assessment.studentAssessments.length > 0,"to":{
                  name: 'Grades',
                  query: {
                    assessmentId: assessment.id,
                    selection: _vm.selectedStudent,
                  },
                }}},[_vm._v(" Grade ")])],1)],1)}),1):_c('div',[_c('v-card',{staticClass:"mt-3",attrs:{"elevation":"4","outlined":""}},[_c('v-card-text',[_vm._v(" No tasks available. ")])],1)],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"red accent-4","dark":""},on:{"click":function($event){_vm.tasksDialog = false}}},[_vm._v(" Close ")])],1)],1):_vm._e()],1),_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.xs ? 'justify-center' : '',attrs:{"cols":"12"}},[_c('h2',{staticClass:"mx-2"},[_vm._v("Students Table")])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('FilterSelect',{attrs:{"filterLabel":"Sections","filterOptions":_vm.sections},on:{"change":function($event){_vm.filterStudentsBySection = $event}}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('SearchQuery',{on:{"search":function($event){_vm.searchInputQuery = $event}}})],1)],1)],1),_c('v-data-table',{attrs:{"search":_vm.searchInputQuery,"loading":_vm.loading,"headers":_vm.studentTasksHeaders,"items":_vm.filteredStudents,"footer-props":{
      itemsPerPageOptions: _vm.studentsPerPage,
    }},scopedSlots:_vm._u([{key:"item.lessons",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","label":""}},[_vm._v(" "+_vm._s(item.lessons.length)+" ")])]}},{key:"item.writtenWorks",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.assessmentCount("writtenWorks", item.assessments))+" ")])]}},{key:"item.performanceTasks",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","small":"","label":""}},[_vm._v(" "+_vm._s(_vm.assessmentCount("performanceTasks", item.assessments))+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","dark":"","color":"green accent-4"},on:{"click":function($event){return _vm.selectStudent(item)}}},[_vm._v(" View Tasks ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }