<template>
  <v-card class="elevation-2">
    <v-dialog width="500px" v-model="tasksDialog">
      <v-card v-if="selectedStudent">
        <v-card-title :class="`main-${subject.color}-bg white--text`">
          {{ selectedStudent.firstName }} {{ selectedStudent.lastName }}
        </v-card-title>
        <v-card-text class="pa-3">
          <h4>Tasks :</h4>
          <div v-if="selectedStudent.assessments.length > 0">
            <v-card
              class="mt-3"
              elevation="4"
              outlined
              v-for="(assessment, index) in selectedStudent.assessments"
              :key="index"
            >
              <v-card-title>
                <h5>{{ assessment.title }}</h5>
              </v-card-title>
              <v-card-subtitle>
                <v-chip
                  v-show="assessment.performanceTask"
                  outlined
                  label
                  class="mr-2"
                  small
                >
                  Performance Tasks
                </v-chip>
                <v-chip v-show="assessment.writtenWork" outlined label small>
                  Written Works
                </v-chip>
              </v-card-subtitle>
              <v-card-text>
                <p>
                  Attempts : {{ assessment.studentAssessments.length }} out of
                  {{ assessment.attempts }}
                </p>
                <p>
                  Score :
                  {{
                    getScore(
                      assessment.studentAssessments,
                      assessment.recordHighest
                    )
                  }}
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-end">
                <v-btn
                  :disabled="assessment.studentAssessments.length === 0"
                  color="blue accent-4"
                  :dark="assessment.studentAssessments.length > 0"
                  :to="{
                    name: 'Grades',
                    query: {
                      assessmentId: assessment.id,
                      selection: selectedStudent,
                    },
                  }"
                >
                  Grade
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <div v-else>
            <v-card class="mt-3" elevation="4" outlined>
              <v-card-text> No tasks available. </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="tasksDialog = false" color="red accent-4" dark>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-row>
        <v-col
          :class="$vuetify.breakpoint.xs ? 'justify-center' : ''"
          class="d-flex align-center"
          cols="12"
        >
          <h2 class="mx-2">Students Table</h2>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <FilterSelect
            filterLabel="Sections"
            :filterOptions="sections"
            @change="filterStudentsBySection = $event"
          />
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="6">
          <SearchQuery @search="searchInputQuery = $event" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :search="searchInputQuery"
      :loading="loading"
      :headers="studentTasksHeaders"
      :items="filteredStudents"
      :footer-props="{
        itemsPerPageOptions: studentsPerPage,
      }"
    >
      <template v-slot:[`item.lessons`]="{ item }">
        <v-chip outlined small label>
          {{ item.lessons.length }}
        </v-chip>
      </template>
      <template v-slot:[`item.writtenWorks`]="{ item }">
        <v-chip outlined small label>
          {{ assessmentCount("writtenWorks", item.assessments) }}
        </v-chip>
      </template>
      <template v-slot:[`item.performanceTasks`]="{ item }">
        <v-chip outlined small label>
          {{ assessmentCount("performanceTasks", item.assessments) }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="selectStudent(item)" small dark color="green accent-4">
          View Tasks
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import SearchQuery from "@/components/SearchQuery";
import FilterSelect from "@/components/FilterSelect";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Students",
  },
  name: "StudentIndex",
  components: {
    SearchQuery,
    FilterSelect,
  },
  data() {
    return {
      searchInputQuery: "",
      filterStudentsBySection: "",
      loading: false,
      tasksDialog: false,
      selectedStudent: null,
      studentsPerPage: [10, 15, 25, 50, 100],
      studentTasksHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Section",
          align: "center",
          sortable: true,
          value: "sectionName",
        },
        {
          text: "Modules",
          align: "center",
          sortable: false,
          value: "lessons",
        },
        {
          text: "WW",
          align: "center",
          sortable: false,
          value: "writtenWorks",
        },
        {
          text: "PT",
          align: "center",
          sortable: false,
          value: "performanceTasks",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      subjectData: "_subjectsModule/getSubjectTaskGrade",
    }),
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    filteredStudents() {
      let studentsEnrolled = [],
        sections =
          this.subjectData.sections?.length > 0
            ? [...this.subjectData.sections]
            : [],
        irregularStudents =
          this.subjectData.irregular?.length > 0
            ? [...this.subjectData.irregular]
            : [];

      if (sections.length > 0) {
        sections.map((section) => {
          section.students?.map((student) => {
            studentsEnrolled.push({
              ...student,
              sectionId: section.id,
              sectionName: section.name,
            });
          });
        });
      }

      if (irregularStudents.length > 0) {
        irregularStudents.map((irregular) => {
          studentsEnrolled.push({
            ...irregular,
            sectionId: "irregular",
            sectionName: "Irregular",
          });
        });
      }

      if (this.filterStudentsBySection) {
        studentsEnrolled = studentsEnrolled?.filter(
          (student) => student.sectionId == this.filterStudentsBySection
        );
      }

      return studentsEnrolled;
    },
    sections() {
      let sectionOptions = [],
        sections =
          this.subjectData.sections?.length > 0
            ? [...this.subjectData.sections]
            : [],
        irregularStudents =
          this.subjectData.irregular?.length > 0
            ? [...this.subjectData.irregular]
            : [];

      if (sections.length > 0) {
        sections.map((section) => {
          sectionOptions.push({
            text: section.name,
            value: section.id,
          });
        });
      }

      if (irregularStudents.length > 0) {
        sectionOptions.push({
          text: "Irregular",
          value: "irregular",
        });
      }

      return sectionOptions;
    },
  },
  async mounted() {
    //get students enrolled and tasks assigned
    const subjectId = this.subject.id;
    this.loading = true;
    await this.$store.dispatch("_subjectsModule/getSubjectTaskGradeById", {
      subjectId: subjectId,
      assessmentId: null,
    });
    this.loading = false;
  },
  methods: {
    getScore(studentAssessments, recordHighest) {
      let score = "N/A",
        studentScores = [];

      studentAssessments?.map((assessment) => {
        if (assessment.score !== null) {
          studentScores.push(assessment.score);
        }
      });

      if (studentScores.length > 0) {
        if (recordHighest) {
          score = Math.max(...studentScores);
        } else {
          score = studentScores.at(-1);
        }
      }

      return score;
    },
    selectStudent(selection) {
      this.selectedStudent = selection;
      this.tasksDialog = true;
    },
    assessmentCount(key, assessments) {
      let assessmentCount = 0;

      if (key === "writtenWorks") {
        assessments.map((assessment) => {
          if (assessment.writtenWork) {
            assessmentCount++;
          }
        });
      } else {
        assessments.map((assessment) => {
          if (assessment.performanceTask) {
            assessmentCount++;
          }
        });
      }

      return assessmentCount;
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
